import { Box, Image, Link, Text, chakra, Skeleton } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { ndash } from 'lib/html-entities';
import isBrowser from 'lib/isBrowser';

type AdData = {
  ad: {
    name: string;
    description_short: string;
    thumbnail: string;
    url: string;
    cta_button: string;
    impressionUrl?: string;
  };
}

// const MOCK: AdData = {
//   ad: {
//     url: 'https://unsplash.com/s/photos/cute-kitten',
//     thumbnail: 'https://placekitten.com/40/40',
//     name: 'All about kitties',
//     description_short: 'To see millions picture of cute kitties',
//     cta_button: 'click here',
//   },
// };

const CoinzillaTextAd = ({ className }: {className?: string}) => {
  return <></>;
};

export default chakra(CoinzillaTextAd);
